import React from "react";
import { Document, Page, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleA4";
import BodyA4 from "./Components/BodyA4";
import { isPresupuestoA4 } from "../utils";

export default function PresupuestoA4({ data, config, tipo }) {
  console.log(data, "data");
  const getPaddingBottom = () => {
    if (isPresupuestoA4(tipo)) {
      return data.detalles.length > 17 ? 150 : 200;
    }
    return 10;
  };

  return (
    <Document title={"Presupuesto"}>
      <Page
        style={{
          paddingBottom: getPaddingBottom(),
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        size={"A4"}
      >
        {isPresupuestoA4(tipo) || !config.es_duplicado ? (
          <BodyA4 data={data} tipo={tipo} config={config} />
        ) : (
          [0, 1].map(() => <BodyA4 data={data} tipo={tipo} config={config} />)
        )}
        <Text
          style={
            isPresupuestoA4
              ? styles.pageNumber
              : {
                  fontSize: 10,
                  textAlign: "center",
                  color: "grey",
                }
          }
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
