import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  Dialog,
  Divider,
  Backdrop,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonModalMaterial } from "../../../components/ait-reusable/ButtonModalMaterial";
import { dataPostPresupuesto } from "../Importes/utils";
import { useSelector } from "react-redux";
import moment from "moment";
import BotonesFormato from "./Componentes/BotonesFormato";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../components/ait-reusable/Button/ButtonCancelar";
import AutocompleteEmpleado from "../../../components/ait-reusable/AutocompleteEmpleado";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import { ValidarEmail } from "../../../utils/validateEmail";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  button: {
    "& .MuiButton-label": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  },
}));

const ModalPresupuesto = ({
  open,
  presupuesto,
  toggle,
  submit,
  openBackdrop,
  handleOpenModalPdf,
  dataEmpleado,
  setLoading,
  configCCC,
  montosCalculados,
  totalesPorImpuesto,
  mediosPagoProcesadosConMontosEImpuestos,
}) => {
  const isFCEMA = presupuesto.tipoComprobante.nombre === "FCEM A";
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { empleado } = useSelector((state) => state.loginReducer);
  const { cliente, descripcion } = useSelector((store) => store.presupuesto);
  const classes = useStyles();
  const formatoComprobante = localStorage.getItem("formatoComprobante");
  const [errorResponsable, setErrorResponsable] = useState(false);
  const [errorCorreo, setErrorCorreo] = useState(false);
  const [correoCliente, setCorreoCliente] = useState(
    cliente ? cliente.cliente.correoElectronico : "",
  );

  const calcularFechaDeVigencia = () => {
    let today = new Date();
    let cantDias = today.setDate(
      today.getDate() + configGeneral.vigencia_presupuesto,
    );
    let fecha_vigencia = moment(new Date(cantDias)).format("YYYY-MM-DD");
    return fecha_vigencia;
  };
  const [reservaStock, setReservaStock] = useState(
    configGeneral.reservar_stock,
  );
  const [observacion, setObservacion] = useState("");
  const [fechaVigencia, setFechaVigencia] = useState(calcularFechaDeVigencia);
  const [formatVenta, setFormatVenta] = useState(null);
  const newData = dataPostPresupuesto(presupuesto);

  const tomorrowDate = () => {
    let today = new Date();
    let day_in_ms = 24 * 60 * 60 * 1000;
    let tomorrow = new Date(today.getTime() + day_in_ms);
    return tomorrow;
  };

  const changeFormatVenta = (option) => {
    let newOption = option === formatVenta ? null : option;
    setFormatVenta(newOption);
  };

  const validateFormat = () => {
    if (dataEmpleado.useEmpleado.empleado) {
      localStorage.setItem(
        "responsable_venta",
        dataEmpleado.useEmpleado.empleado.idEmpleado,
      );
      dataEmpleado.useQueryEmpleado.setQuery("");

      delete newData.montoSubtotal;
      delete newData.montoTotal;

      let dataPost = {
        ...newData,
        observacion: observacion,
        reserva_stock: reservaStock,
        fecha_vigencia: fechaVigencia,
        responsable: dataEmpleado.useEmpleado.empleado.idEmpleado,
        monto_importe: montosCalculados.subtotal,
        monto_descuento: montosCalculados.montoDescuento,
        monto_interes: montosCalculados.montoInteres,
        monto_subtotal:
          montosCalculados.montoConDescuentoEInteresSinIvaEImpuestos,
        monto_iva: montosCalculados.montoIVA,
        monto_total_impuestos: montosCalculados.montoTotalEnImpuestos,
        monto_total: montosCalculados.montoTotalConIvaEImpuestos,
        totales_por_impuesto:
          totalesPorImpuesto.length > 0 ? totalesPorImpuesto : null,
        medioPago: mediosPagoProcesadosConMontosEImpuestos.map((imp) => ({
          ...imp,
          monto: imp.totalConIvaEImpuestos,
        })),
      };
      if (formatVenta === "WPA4") {
        handleOpenModalPdf("print", dataPost, formatVenta);
      } else {
        let newFormat = null;
        switch (formatVenta) {
          case "A4":
            newFormat = "print";
            break;
          case "Ticket":
            newFormat = "ticket";
            break;
          case "A4 Ahorro":
            newFormat = "ahorro";
            break;
          default:
        }
        if (formatVenta === "Email") {
          validateEmail(
            newFormat,
            dataPost,
            newFormat ? newFormat : null,
            isFCEMA,
            formatVenta,
            correoCliente,
          );
        } else {
          submit(
            newFormat,
            dataPost,
            newFormat ? newFormat : null,
            isFCEMA,
            formatVenta,
            correoCliente,
          );
        }
      }
    } else {
      setErrorResponsable(true);
    }
  };

  const validateEmail = (newFormat, dataPost) => {
    if (correoCliente === "" || !ValidarEmail(correoCliente)) {
      setErrorCorreo(true);
    } else {
      submit(
        newFormat,
        dataPost,
        newFormat ? newFormat : null,
        isFCEMA,
        formatVenta,
        correoCliente,
      );
    }
  };

  useEffect(() => {
    let selectedConfig = null;
    switch (formatoComprobante) {
      case "Ticket":
        selectedConfig = configGeneral.en_ticket ? "Ticket" : null;
        break;
      case "A4":
        selectedConfig = configGeneral.en_a4 ? "A4" : null;
        break;
      case "A4 Ahorro":
        selectedConfig = configGeneral.ahorro_papel ? "A4 Ahorro" : null;
        break;
      default:
        selectedConfig =
          formatoComprobante === "null" ? null : formatoComprobante;
        break;
    }
    setFormatVenta(selectedConfig);
  }, []);

  useEffect(() => {
    if (
      presupuesto &&
      presupuesto.observacion &&
      presupuesto.observacion.length > 0 &&
      presupuesto.observacion[0].detalle !== ""
    ) {
      setObservacion(presupuesto.observacion[0].detalle);
    } else if (descripcion) {
      setObservacion(descripcion);
    }
  }, [presupuesto, descripcion]);

  return (
    <Dialog
      // maxWidth={"xs"}
      fullWidth
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <ButtonModalMaterial id="customized-dialog-title" onClose={toggle}>
        Confirmar presupuesto
      </ButtonModalMaterial>
      <Divider />
      <div style={{ margin: 10, paddingLeft: 25, paddingRight: 25 }}>
        {configCCC &&
          configCCC.dataConfig.mensaje !== "" &&
          configCCC.dataConfig.mostrarAdvertencia && (
            <Grid
              container
              spacing={2}
              className="d-flex justify-content-start"
            >
              <Grid item xs={12} className="pb-2">
                <Alert
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  severity={"warning"}
                >
                  <strong>{configCCC.dataConfig.mensaje}</strong>
                </Alert>
              </Grid>
            </Grid>
          )}
        <Grid container spacing={2} className="mb-1">
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  autoFocus
                  checked={reservaStock}
                  color="primary"
                  onChange={() => setReservaStock(!reservaStock)}
                  name="reserva"
                />
              }
              label="Reservar productos"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} className="mb-1">
            <AutocompleteEmpleado
              useEmpleado={dataEmpleado.useEmpleado}
              useQueryEmpleado={dataEmpleado.useQueryEmpleado}
              errorResponsable={errorResponsable}
              setErrorResponsable={setErrorResponsable}
              empleado={empleado}
              setLoading={setLoading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mb-1">
          {!isFCEMA ? (
            <Grid item xs={12}>
              <TextField
                id="fechaVigencia"
                label="Fecha de vigencia"
                type="date"
                size="small"
                fullWidth
                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                onChange={(obj) => setFechaVigencia(obj.target.value)}
                value={fechaVigencia}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: moment(tomorrowDate()).format("YYYY-MM-DD"),
                }}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextField
              style={{ backgroundColor: "white" }}
              name="observacion"
              fullWidth
              multiline
              inputProps={{ maxLength: 150 }}
              label={"Observación"}
              value={observacion}
              onChange={(e) => setObservacion(e.target.value)}
              helperText={`${observacion.length} de 150 caracteres`}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <BotonesFormato
          formatVenta={formatVenta}
          changeFormatVenta={changeFormatVenta}
          configGeneral={configGeneral}
          errorCorreo={errorCorreo}
          correoCliente={correoCliente}
          setErrorCorreo={setErrorCorreo}
          setLoading={setLoading}
          setCorreoCliente={setCorreoCliente}
        />
        {formatVenta === "Email" && (
          <Grid
            container
            justifyContent="center"
            item
            xs={12}
            style={{ paddingTop: "2em" }}
          >
            <Chip
              style={{
                marginTop: "-1rem",
                padding: "0.5rem",
                color: "#ffffff",
                backgroundColor: configGeneral.email ? "#3f51b5" : "#ff9800",
              }}
              size="small"
              label={
                configGeneral.email
                  ? "Se enviará el comprobante a tu email para corroborar su llegada"
                  : "No recibirás el comprobante en tu email porque no tienes uno asociado a tu cuenta"
              }
              icon={
                configGeneral.email ? (
                  <InfoIcon style={{ color: "#ffffff" }} />
                ) : (
                  <WarningIcon style={{ color: "#ffffff" }} />
                )
              }
            />
          </Grid>
        )}
        <Grid
          container
          spacing={2}
          className="pt-2"
          style={{ justifyContent: "right" }}
        >
          <Grid item xs={4} sm={4} lg={4} style={{ paddingLeft: 0 }}>
            <ButtonCancelar
              click={toggle}
              message={"CANCELAR"}
              fullwidth={true}
            />
          </Grid>

          <Grid item xs={4} sm={4} lg={4} style={{ paddingRight: 0 }}>
            <ButtonAceptar
              disabled={openBackdrop}
              click={() => validateFormat()}
              message={"ACEPTAR"}
              fullwidth={true}
            />
          </Grid>
        </Grid>
      </div>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default ModalPresupuesto;
