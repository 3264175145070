import React from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";

export default function DetalleMedioPago({ medioSelected }) {
  const { pagos_tarjeta, medio_pago, monto } = medioSelected;

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <span className="material-icons">minimize</span>
          <span className="pr-2 fontBold">{medio_pago.toUpperCase()}</span>
          <span className="pr-2">{`Monto: $${Number(monto).toLocaleString(
            "es-AR",
          )}`}</span>
          {pagos_tarjeta && (
            <React.Fragment>
              <span className="pr-2">{pagos_tarjeta.nombre_tarjeta}</span>

              <span>{`Cuotas: ${pagos_tarjeta.cantidad_cuotas} - Interes: ${(
                pagos_tarjeta.porcentaje * 100
              ).toFixed(2)}%`}</span>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
