import { successNotification } from "../../../components/Notifications";
import request from "../../../requests/request";
import { sendMailPresupuesto } from "../../../requests/urls";

export const dataPostPresupuesto = (presupuesto) => {
  let newArray = presupuesto.detalles.map((x) => ({
    ...x,
    lista_precios: x.idListaPrecio ? x.idListaPrecio : null,
    descripcion_personalizada: x.descripcionPersonalizada
      ? x.descripcionPersonalizada
      : null,
  }));

  let newData = {
    id_presupuesto: presupuesto.id_presupuesto,
    detalles: newArray,
    cliente: presupuesto.cliente,
    tipoComprobante: presupuesto.tipoComprobante,
    medioPago: presupuesto.medioPago,
    descuento: presupuesto.descuento,
    montoTotal: Number(presupuesto.montos.montoTotal.toFixed(2)),
    montoSubtotal: Number(presupuesto.montos.subtotal.toFixed(2)),
  };

  return newData;
};

/**
 * Calcula el monto de la comision en caso de que el tiene
 * maneje comisiones.
 * @param {*} monto
 * @param {*} cliente
 */
export const calcularComision = (monto, cliente) => {
  if (cliente) {
    const { es_comision, comision } = cliente;
    if (es_comision) {
      return Number(
        (comision ? Number(monto * (Number(comision) / 100)) : 0).toFixed(2),
      );
    }
    return 0;
  }
  return 0;
};

export const sendMailPresupuestoOnPost = async (
  idPresupuesto,
  correoCliente,
  setOpenBackdrop,
  callbackOnComplete,
) => {
  setOpenBackdrop(true);
  try {
    const response = await request({
      method: "GET",
      url: sendMailPresupuesto(idPresupuesto),
      params: { mail: correoCliente },
    });
    if (response.status === 201 || response.status === 200) {
      successNotification(
        "Email enviado con éxito. Puede avisar que se revise la bandeja de entrada.",
      );
    }
  } catch (error) {
    console.error(error);
  } finally {
    setOpenBackdrop(false);
    // Llamamos a la función de retorno de llamada si está definida
    callbackOnComplete && callbackOnComplete();
  }
};

export const validateImpuestos = (impPresupuesto, impNuevos) => {
  let error = false;
  let impuestosPresupuesto = impPresupuesto ? impPresupuesto : [];

  if (impuestosPresupuesto.length !== impNuevos.length) {
    error = true;
  } else {
    const newImpuPresupuesto = impuestosPresupuesto.map((imp) => ({
      ...imp,
      id: imp.id_tax_type,
      alicuota: imp.aliquot,
      idTax: imp.id_tax_type,
    }));

    const newImpNuevos = impNuevos.map((imp) => ({
      ...imp,
      idTax: imp.tax_type,
    }));

    const dataImpuestos =
      newImpuPresupuesto.length > newImpNuevos.length
        ? { impSelected: newImpuPresupuesto, impNoSelected: newImpNuevos }
        : { impSelected: newImpNuevos, impNoSelected: newImpuPresupuesto };

    dataImpuestos.impSelected.forEach((imp) => {
      let repetido = dataImpuestos.impNoSelected.find(
        (impuesto) =>
          impuesto.idTax === imp.idTax &&
          Number(impuesto.alicuota) === Number(imp.alicuota),
      );
      if (!repetido) {
        error = true;
      }
    });
  }

  return error;
};
