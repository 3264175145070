import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleTicket";
import HeaderTicket from "./Components/HeaderTicket";
import TotalesTicket from "./Components/TotalesTicket";
import { formatCurrency, parseDate } from "../../../../utils/parsers";
import ResumenPagos from "./Components/ResumenPagos";

export default function PresupuestoTicket({ data, config }) {
  const handleRenderDescripcionDetalle = (detalle) => {
    if (detalle.descripcionPersonalizada) {
      return (
        detalle.descripcionPersonalizada.substr(0, 30).trim() +
        (detalle.descripcionPersonalizada.length > 30 ? "..." : "")
      );
    }

    if (config.ocultar_codigo_pdf) {
      return (
        detalle.descripcion.substr(0, 30).trim() +
        (detalle.descripcion.length > 30 ? "..." : "")
      );
    }

    return (
      detalle.descripcion_ticket.substr(0, 30).trim() +
      (detalle.descripcion_ticket.length > 30 ? "..." : "")
    );
  };

  return (
    <Document title={"Presupuesto"}>
      <Page style={styles.page} size={{ width: 226.77, minHeight: 400 }}>
        <View>
          <HeaderTicket
            logoEmpresa={config && config.url_imagen ? config.url_imagen : null}
            data={data}
            config={config}
          />
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>{`Cliente: ${
                data.cliente && data.cliente.cliente
                  ? data.cliente.cliente.razonSocial
                  : "Consumidor Final"
              }`}</Text>
              {data.cliente && data.cliente.cliente && (
                <Text style={styles.text}>{`CUIT: ${
                  data.cliente.cliente.CUIT || "---"
                }`}</Text>
              )}
              {data.cliente && data.cliente.cliente && (
                <Text style={styles.text}>{`Domicilio: ${
                  data.cliente.cliente.domicilio || "---"
                }`}</Text>
              )}
              {data.cliente ? (
                <Text style={styles.text}>{`Correo electrónico: ${
                  data.cliente.cliente.correoElectronico || "---"
                }`}</Text>
              ) : null}
              {data.cliente ? (
                <Text style={styles.text}>{`Tel.: ${
                  data.cliente.cliente.telefonoContacto || "---"
                }`}</Text>
              ) : null}
            </View>
          </View>
          <View>
            <Text style={{ marginLeft: 10, marginRight: 10 }}>
              --------------------------------
            </Text>
          </View>
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>{`Observaciones: ${
                data.observacion &&
                data.observacion.length > 0 &&
                data.observacion[0].detalle !== ""
                  ? data.observacion[0].detalle
                  : "Ninguna"
              }`}</Text>
            </View>
          </View>
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>{`Responsable: ${
                data.responsable
                  ? `${data.responsable.nombre} ${data.responsable.apellido}`
                  : "---"
              }`}</Text>
            </View>
          </View>
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>{`Forma de Pago:`}</Text>
              {data.medioPago ? (
                <ResumenPagos medios={data.medioPago} styles={styles.text} />
              ) : (
                <Text style={styles.text2}>{"---"}</Text>
              )}
            </View>
          </View>
          <View>
            <Text style={{ marginLeft: 10, marginRight: 10 }}>
              --------------------------------
            </Text>
          </View>
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.textbold}>Descripción</Text>
              <Text style={styles.textbold}>Cant/Precio Unit.</Text>
            </View>
            <View style={styles.containerColumn2}>
              <Text style={styles.textbold}>IMPORTE</Text>
            </View>
          </View>
          <View>
            <Text style={{ marginLeft: 10, marginRight: 10 }}>
              --------------------------------
            </Text>
          </View>
          {data.detalles.map((x, index) => (
            <View key={index} style={styles.containerRow}>
              <View style={styles.containerColumn}>
                <Text style={styles.textDescriptionItem}>
                  {handleRenderDescripcionDetalle(x)}
                </Text>
                <Text style={styles.text}>
                  {parseFloat(x.cantidad).toFixed(3)} {" x "}
                  {formatCurrency(x.precio)}
                </Text>
              </View>
              <View style={styles.containerColumn}>
                <Text style={styles.text}>{formatCurrency(x.subtotal)}</Text>
              </View>
            </View>
          ))}
          <View>
            <Text style={{ marginLeft: 10, marginRight: 10 }}>
              --------------------------------
            </Text>
          </View>
          <TotalesTicket data={data} />

          <View style={{ paddingBottom: 10 }}>
            <Text style={{ padding: 10, fontSize: 11, textAlign: "center" }}>
              {`VÁLIDO HASTA ${parseDate(data.vencimiento, "DD/MM/YYYY")}`}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
