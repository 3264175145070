import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "shards-react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import DetalleMedioPago from "./DetalleMedioPago";

function ModalDetalle({ open, selected, toggle }) {
  const [titulo, setTitulo] = useState("");
  const [numeroCompr, setNumeroCompr] = useState("");
  const [montoTotal, setMontoTotal] = useState("");
  const [fechaAlta, setFechaAlta] = useState("");
  const [detalle, setDetalle] = useState([]);
  const { configGeneral } = useSelector((state) => state.configGeneral);

  useEffect(() => {
    completarDatos();
  }, [selected]);

  const validarCodigo = (item) => {
    if (configGeneral.codigo_comprobante) {
      if (
        configGeneral.codigo_comprobante === "sin" ||
        configGeneral.codigo_comprobante === ""
      ) {
        return "---";
      } else if (configGeneral.codigo_comprobante === "proveedor") {
        if (!item.codProveedor || item.codProveedor === "") return "---";
        else return item.codProveedor;
      } else if (configGeneral.codigo_comprobante === "original") {
        if (!item.codOriginal || item.codOriginal === "") return "---";
        else return item.codOriginal;
      } else if (configGeneral.codigo_comprobante === "auxiliar") {
        if (!item.codAuxiliar || item.codAuxiliar === "") return "---";
        else return item.codAuxiliar;
      }
    } else {
      return "---";
    }
  };

  const completarDatos = () => {
    setTitulo("presupuesto");
    setNumeroCompr(selected.id_presupuesto);
    setMontoTotal(selected.montoTotal);
    setFechaAlta(selected.fecha_hora);
    setDetalle(selected.detalles);
  };

  const { cliente, descuento, observacion, responsable, medioPago } = selected;

  const handleRenderDescripcionDetalle = (item) => {
    return (
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          style={{ gap: 5 }}
        >
          <p style={{ margin: 0 }}>{`${validarCodigo(item)} -`}</p>
          <p style={{ margin: 0 }}>
            {item.descripcion ? item.descripcion : ""}
          </p>
        </Box>
        {item.descripcion_personalizada ? (
          <p style={{ margin: 0, fontSize: 12, fontWeight: "bold" }}>
            {item.descripcion_personalizada}{" "}
          </p>
        ) : null}
      </Box>
    );
  };

  const trProductos = (detalle) => {
    return detalle.map((item, idx) => (
      <TableRow key={idx}>
        <TableCell align="center">{validarCodigo(item)}</TableCell>
        <TableCell align="center">{item.proveedor || "---"}</TableCell>
        <TableCell align="center">
          {item.nombre_lista_precios || "---"}
        </TableCell>
        <TableCell
          align="center"
          style={{
            // maxWidth: 300,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={item.descripcion}
        >
          {/* {item.descripcion} */}
          {handleRenderDescripcionDetalle(item)}
        </TableCell>
        <TableCell align="center">{item.cantidad}</TableCell>

        {configGeneral.incluir_precio_lista_presupuesto ? (
          <TableCell align="center">
            $
            {parseFloat(
              item.precios && item.precios.lista ? item.precios.lista : 0,
            ).toLocaleString("es-AR")}
          </TableCell>
        ) : null}

        {configGeneral.incluir_precio_costo_presupuesto ? (
          <TableCell align="center">
            $
            {parseFloat(
              item.precios && item.precios.costo ? item.precios.costo : 0,
            ).toLocaleString("es-AR")}
          </TableCell>
        ) : null}

        <TableCell align="center">
          ${parseFloat(item.precio).toLocaleString("es-AR")}
        </TableCell>

        <TableCell align="center">
          ${parseFloat(item.subtotal).toLocaleString("es-AR")}
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Modal open={open} toggle={toggle} size="lg">
      <ModalHeader>
        Detalle {titulo} nro. {numeroCompr}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="col-4">
            <label htmlFor="">
              <span className="fontBold">Cliente: </span>
              {cliente && cliente.cliente && cliente.cliente.razonSocial
                ? cliente.cliente.razonSocial.toUpperCase()
                : "Consumidor final"}
            </label>
          </Col>
          <Col className="col-4">
            <label htmlFor="">
              <span className="fontBold">Fecha de registro: </span>
              {moment(fechaAlta).format("DD/MM HH:mm")}
            </label>
          </Col>
          <Col className="col-4">
            <label htmlFor="">
              <span className="fontBold">Responsable: </span>
              {responsable
                ? `${responsable.nombre} ${responsable.apellido}`
                : "---"}
            </label>
          </Col>
        </Row>
        <Row>
          <Col className="col-4">
            <label htmlFor="">
              <span className="fontBold">Aplica descuento: </span>
              {descuento
                ? (Number(descuento.porcentaje) * 100).toFixed(2)
                : "---"}
              %
            </label>
          </Col>
          <Col className="col-4">
            <label htmlFor="">
              <span className="fontBold">Observación: </span>
              {observacion &&
              observacion.length > 0 &&
              observacion[0].detalle !== ""
                ? observacion[0].detalle
                : "---"}
            </label>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <label htmlFor="">
              <span className="fontBold">Medio pago: </span>
              {medioPago.map((medio, index) => {
                return (
                  <Row key={index}>
                    <Col className="col-12" style={{ paddingTop: 0 }}>
                      <DetalleMedioPago medioSelected={medio} />
                    </Col>
                  </Row>
                );
              })}
            </label>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <TableContainer style={{ maxHeight: "300px", overflow: "auto" }}>
              <Table stickyHeader size="small" aria-label=" a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      key="detalle"
                      style={{
                        backgroundColor: "#007bff",
                        color: "#fff",
                        fontWeight: "bolder",
                      }}
                    >
                      Código
                    </TableCell>
                    <TableCell
                      align="center"
                      key="detalle"
                      style={{
                        backgroundColor: "#007bff",
                        color: "#fff",
                        fontWeight: "bolder",
                      }}
                    >
                      Proveedor
                    </TableCell>
                    <TableCell
                      align="center"
                      key="detalle"
                      style={{
                        backgroundColor: "#007bff",
                        color: "#fff",
                        fontWeight: "bolder",
                      }}
                    >
                      L. Precios
                    </TableCell>
                    <TableCell
                      align="center"
                      key="detalle"
                      style={{
                        backgroundColor: "#007bff",
                        color: "#fff",
                        fontWeight: "bolder",
                      }}
                    >
                      Descripcion
                    </TableCell>
                    <TableCell
                      align="center"
                      key="cantidad"
                      style={{
                        backgroundColor: "#007bff",
                        color: "#fff",
                        fontWeight: "bolder",
                      }}
                    >
                      Cantidad
                    </TableCell>
                    {configGeneral.incluir_precio_lista_presupuesto ? (
                      <TableCell
                        align="center"
                        key="precioLista"
                        style={{
                          backgroundColor: "#007bff",
                          color: "#fff",
                          fontWeight: "bolder",
                        }}
                      >
                        Precio Lista
                      </TableCell>
                    ) : null}

                    {configGeneral.incluir_precio_costo_presupuesto ? (
                      <TableCell
                        align="center"
                        key="precioCosto"
                        style={{
                          backgroundColor: "#007bff",
                          color: "#fff",
                          fontWeight: "bolder",
                        }}
                      >
                        Precio Costo
                      </TableCell>
                    ) : null}

                    <TableCell
                      align="center"
                      key="precioUnit"
                      style={{
                        backgroundColor: "#007bff",
                        color: "#fff",
                        fontWeight: "bolder",
                      }}
                    >
                      Precio Unit.
                    </TableCell>

                    <TableCell
                      align="center"
                      key="subtotal"
                      style={{
                        backgroundColor: "#007bff",
                        color: "#fff",
                        fontWeight: "bolder",
                      }}
                    >
                      Subtotal
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{trProductos(detalle)}</TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "space-between" }}>
        <Col className="col-7">
          <label htmlFor="" style={{ fontSize: "large" }}>
            <span className="fontBold">Monto total: </span> $
            {parseFloat(montoTotal).toLocaleString("es-AR")}
          </label>
        </Col>
        <Button theme="danger" className="fontBold" onClick={toggle}>
          SALIR
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalDetalle;
